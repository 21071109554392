import MainApiService from "@/services/api";

const ManagerServices = {
  getPayments(params) {
    return MainApiService.get("payments/manager/approved-by-employees", {
      params,
    });
  },
  sendForPayment(params) {
    return MainApiService.post("payments/manager/send-for-payment", params);
  },
  getArchive(params) {
    return MainApiService.get("payments", { params });
  },
  getManagerNewTreaties() {
    return MainApiService.get("new-treaties/manager/active");
  },
  declineManagerNewTreatiesItem(params) {
    return MainApiService.post("new-treaties/manager/decline ", params);
  },
  approveManagerNewTreatiesItem(params) {
    return MainApiService.post("new-treaties/manager/approve", params);
  },

  //isesf requests
  getIsesfItems() {
    return MainApiService.get("isesfs/manager/active");
  },
  approveIsesfItems(params) {
    return MainApiService.post("isesfs/manager/approve", params);
  },
  declineIsesfItems(params) {
    return MainApiService.post("isesfs/manager/decline", params);
  },
  archiveIsesfItems(params) {
    return MainApiService.post("isesfs/manager/archive", params);
  },

  //dashboard requests
  getEntityClientsCount() {
    return MainApiService.get("users/count?role.type=client&is_entity=true");
  },
  getIndividualClientsCount() {
    return MainApiService.get("users/count?role.type=client&is_entity=false");
  },
  getUsersInvolvementAnalytics() {
    return MainApiService.get("dashboard/users/analytics");
  },
  getRentsAnalytics() {
    return MainApiService.get("dashboard/rents/analytics");
  },
  getElectricitiesAnalytics() {
    return MainApiService.get("dashboard/electricities/analytics");
  },

  //rents requests
  getRents() {
    return MainApiService.get("rents/manager/getRentApplicationsToSign");
  },
  signRents(params) {
    return MainApiService.post("/rents/manager/sign", params);
  },
  archiveRentsItems(params) {
    return MainApiService.post("rents/manager/archive", params);
  },
};

export default ManagerServices;
