<template>
  <b-form-group
      :label="label"
      :label-sr-only="!label"
      :content-cols="horizontal ? 8: 12"
      :class="size"
      class="oda-form-group"
      label-class="oda-form-group__label"
  >
    <b-form-textarea
        v-if="textarea"
        v-model="newValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :data-testid="testId"
        :required="required"
        class="oda-form-input oda-form-textarea"
        @input="change"
    ></b-form-textarea>

    <b-form-checkbox
        v-else-if="checkbox"
        v-model="newValue"
        :disabled="disabled"
        class="oda-form-checkbox"
        @input="change"
    >
      {{ placeholder }}
    </b-form-checkbox>

    <b-form-select
        v-else-if="select"
        :options="options"
        :value="newValue"
        :text-field="selectText"
        :value-field="selectValue"
        :disabled="disabled"
        :required="required"
        :class="bgColor"
        class="oda-form-input oda-form-select"
        @change="change"
    >
      <template #first>
        <b-form-select-option
            :value="null"
            disabled
            class="oda-form-select__placeholder"
        >
          {{ placeholder }}
        </b-form-select-option>
      </template>
    </b-form-select>

    <multiselect
        v-else-if="multiselect"
        v-model="newValue"
        :options="options"
        :placeholder="placeholder"
        :show-labels="false"
        :multiple="false"
        :close-on-select="false"
        :clear-on-select="false"
        preserve-search
        class="oda-form-multiselect"
        @input="arrayChange"
        @search-change="onSearchChange"
    >
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span
            v-show="values.length && !isOpen"
            class="multiselect__single"
        >
          {{ values.length }} значение выбрано
        </span>
      </template>
      <div slot="noResult">
        <div class="multiselect__nooptions">
          Результатов нет
        </div>
      </div>
      <template slot="noOptions">
        <div class="multiselect__nooptions">
          Введите значение
        </div>
      </template>
    </multiselect>

<!--    <div-->
<!--        class="oda-form-file__wrap"-->
<!--        v-else-if="file"-->
<!--    >-->
      <b-form-file
          v-model="newValue"
          v-else-if="file"
          :placeholder="placeholder"
          :required="required"
          :accept="accept"
          :disabled="disabled"
          class="oda-form-file"
          @input="change(newValue)"
      >
      </b-form-file>

<!--      <button-->
<!--          v-if="downloadButton"-->
<!--          class="oda-form-file__button oda-button bordered primary feather icon-download"-->
<!--      ></button>-->
<!--    </div>-->

    <b-form-input
        v-else-if="number"
        v-model="newValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :data-testid="testId"
        type="number"
        step="0.01"
        class="oda-form-input"
        @keydown="onKeydown"
        @input="change"
    ></b-form-input>

    <div v-else-if="phone">
      <input
          v-model="newValue"
          v-mask="'87#########'"
          :required="required"
          type="tel"
          placeholder="87_________"
          class="oda-form-input oda-form-input__phone"
          @input="change(newValue)"
      >

    </div>

    <div v-else-if="emptyForm">
      <slot name="input"></slot>
    </div>

    <b-form-input
        v-else
        v-model="newValue"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        :required="required"
        :data-testid="testId"
        :class="bgColor"
        trim
        class="oda-form-input"
        step="0.01"
        @input="change"
    ></b-form-input>
  </b-form-group>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'OdaFormGroup',
  components: {
    Multiselect,
  },
  props: {
    label: String,
    placeholder: [String, Number],
    value: [String, Number, File, Array, Boolean, Object],
    name: String,
    horizontal: Boolean,
    textarea: Boolean,
    checkbox: Boolean,
    number: Boolean,
    phone: Boolean,
    type: String,
    disabled: Boolean,
    size: String,
    select: Boolean,
    multiselect: Boolean,
    options: Array,
    file: Boolean,
    selectText: String,
    selectValue: String,
    required: Boolean,
    accept: String,
    removeButton: Boolean,
    removeLink: Boolean,
    downloadButton: Boolean,
    integer: Boolean,
    emptyForm: Boolean,
    testId: String,
    bgColor: String,
  },

  data() {
    return {
      newValue: null,
    };
  },

  watch: {
    value() {
      this.newValue = this.value;
    },
  },

  mounted() {
    if (this.value) {
      this.newValue = this.value;
    }
  },

  methods: {
    change(value) {
      if (this.phone) {
        this.$emit('input', value);
        return;
      }
      if (this.number) {
        this.$emit('input', value);
        return;
      }
      this.$emit('input', value);
    },
    arrayChange() {
      this.$emit('input', this.newValue);
    },
    onSearchChange(value) {
      this.$emit('search', value);
    },
    onKeydown(e) {
      const { key, keyCode } = e;
      let list = ['e', '-'];
      if (this.integer) list = list.concat([',', '.']);
      if (list.includes(key)) {
        e.preventDefault();
      }

      const { value } = e.target;
      const less = value.split('.')[1];
      if (less && less.length >= 2 && !Number.isNaN(key) && keyCode !== 8) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
