<template>
  <div id="app" ref="app">
    <!--    <plug />-->
    <navbar-mobile v-if="isMobile" />
    <navbar @openModal="openFeedbackModal" v-else />

    <router-view v-if="this.userRole !== 'client'"></router-view>

    <div class="client-block" v-else>
      <a href="https://arenda.tele2.kz/" target="_blank" class="redirect-link">
        Просим клиентов перейти на новую версию crm.
        <br />
        Нажмите сюда
      </a>
    </div>

    <feedback-form
      v-if="currentUser && currentUser.id"
      ref="feedback-modal"
      @close="closeFeedbackModal"
    />
  </div>
</template>

<script>
import Navbar from "./components/Oda-Navbar";
import NavbarMobile from "./components/OdaNavbarMobile";
import OgImage from "./assets/images/og_image.jpeg";
import Plug from "./components/Plug";
import FeedbackForm from "./components/FeedbackForm";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Plug,
    Navbar,
    NavbarMobile,
    FeedbackForm,
  },

  metaInfo() {
    return {
      title: "TELE2.SITE",
      // eslint-disable-next-line no-sparse-arrays
      meta: [
        {
          name: "description",
          content:
            "Быстро, удобно, безопасно. Отправляйте показания счетчика и подписывайте документы онлайн",
        },
        { property: "og:title", content: "TELE2.SITE" },
        { property: "og:site_name", content: "TELE2.SITE" },
        {
          property: "og:description",
          content:
            "Быстро, удобно, безопасно. Отправляйте показания счетчика и подписывайте документы онлайн",
        },
        { property: "og:image", content: window.location.href + OgImage },
      ],
    };
  },

  data() {
    return {
      isMobile: false,
    };
  },

  async mounted() {
    // return;
    // eslint-disable-next-line no-unreachable
    const { path } = this.$route;
    if (path.includes("/du")) {
      this.$refs.app.classList.add("documents-update");
      return;
    }

    await this.$store.dispatch("auth/fetchUser");
    if (this.userRole === "client") {
      if (!this.currentUser.terms_of_use) {
        this.$router.push("/terms-of-use");
      }
    } else if (this.userRole === "employee_curator") {
      this.$store.dispatch(
        "profile/getCounterpartiesByEmployee",
        this.currentUser.id
      );
    }
  },

  created() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },

  methods: {
    windowResize() {
      const windowSize = window.innerWidth;
      this.isMobile = windowSize < 1023;
      this.$store.commit("SET_IS_MOBILE", this.isMobile);
    },
    openFeedbackModal() {
      const component = this.$refs["feedback-modal"];
      component.$refs["modal"].show();
    },
    closeFeedbackModal() {
      const component = this.$refs["feedback-modal"];
      component.$refs["modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "./assets/style/bootstrap.scss";
@import "./assets/style/index.scss";
</style>
