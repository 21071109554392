<template>
  <div class="registry">
    <div class="payments-header">
      <h3>Оплата: Аренда</h3>
    </div>

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <button
              :disabled="items && !items.length"
              class="oda-button primary mr-3"
              @click="availablePick"
            >
              Выбрать
            </button>
            <button
              :class="{ loading: loading }"
              :disabled="!pickAvailable || loading || !pickedItems.length"
              class="oda-button primary"
              @click="showEcpModal"
            >
              Согласовать реестр
            </button>
          </div>

          <button
            :class="{ loading: loading }"
            :disabled="!pickAvailable || loading"
            class="oda-button border ml-auto"
            @click="archiveRegistry"
          >
            Отправить в архив
          </button>
        </div>
        <oda-table
          ref="rents-table"
          :fields="fields"
          :items="items"
          :pickable="pickAvailable"
          :picked-items="pickedItems"
          :widths="widths"
          :disabled-columns="disabledColumns"
          custom-pagination
          row-clickable
          @pick="pickItem"
          @pickAll="pickAll"
          @rowClicked="openPayment"
        />
      </div>
      <b-row>
        <button
          :class="{ loading: loading }"
          :disabled="!pickAvailable || loading"
          class="oda-button primary"
          @click="showEcpModal"
        >
          Согласовать реестр
        </button>

        <button
          :class="{ loading: loading }"
          :disabled="!pickAvailable || loading"
          class="oda-button border ml-auto"
          @click="archiveRegistry"
        >
          Отправить в архив
        </button>
      </b-row>
    </div>
    <sidebar ref="sidebar" @hide="pickedItems = []" />
    <sign-modal ref="ecp-modal" :loading="loading" @send="sendPayments" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OdaTable from "@/components/OdaTable.vue";
import Sidebar from "./sidebar.vue";
import SignModal from "@/components/SignEcpModal.vue";

export default {
  name: "Rent",
  components: {
    OdaTable,
    Sidebar,
    SignModal,
  },

  data() {
    return {
      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "counterparty",
          label: "Контрагент",
          sortable: false,
          searchable: false,
        },
        {
          key: "bts",
          label: "Объект",
          sortable: false,
          searchable: false,
        },
        {
          key: "period",
          label: "Период",
          sortable: false,
        },
        {
          key: "total_sum",
          label: "Сумма в тг",
          sortable: false,
          searchable: false,
        },
        {
          key: "label",
          label: "Статус",
          sortable: false,
        },
      ],
      pickedItems: [],
      pickAvailable: false,
      widths: [65],
      disabledColumns: [0],
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.rents.loading,
      items: (state) => state.rents.manager_rents,
    }),
  },

  mounted() {
    this.getManagerRents();
  },
  methods: {
    ...mapActions("rents", [
      "getManagerRents",
      "signManagerRent",
      "archiveManagerRents",
    ]),
    async openPayment(row) {
      if (this.pickAvailable) {
        this.pickItem(row);
        return;
      }
      this.$store.commit("rents/SET_SIDEBAR_ITEM", row);
      this.$refs.sidebar.toggle();
    },
    availablePick() {
      if (!this.pickAvailable) this.pickedItems = [];

      this.pickAvailable = !this.pickAvailable;

      this.fields.reverse();
      if (this.pickAvailable) this.fields.push({ key: "id", label: "" });
      else this.fields.pop();
      this.fields.reverse();
    },
    pickItem(row) {
      const { id } = row;
      if (this.pickedItems.includes(id)) {
        this.pickedItems = this.pickedItems.filter((item) => item !== id);
      } else {
        this.pickedItems.push(id);
      }
    },
    pickAll() {
      if (this.pickedItems.length === this.items.length) this.pickedItems = [];
      else this.pickedItems = this.items.map((item) => item.id);
    },
    showEcpModal() {
      const component = this.$refs["ecp-modal"];
      component.$refs["modal"].show();
    },
    hideEcpModal() {
      const component = this.$refs["ecp-modal"];
      component.$refs["modal"].hide();
    },
    async sendPayments(ecp) {
      try {
        const formData = new FormData();
        formData.append("datacert", ecp.file);
        formData.append("password", ecp.password);
        formData.append("document_id", JSON.stringify(this.pickedItems));

        await this.signManagerRent(formData);
      } catch (e) {
        console.error(e);
      } finally {
        this.pickedItems = [];
        this.hideEcpModal();
        this.$refs.sidebar.hide();
      }
      this.pickAvailable = false;
      this.pickedItems = [];
      this.$refs["rents-table"].$refs.table.refresh();
      this.getManagerRents();
    },

    async archiveRegistry() {
      try {
        const params = {
          ids: this.pickedItems,
        };

        await this.archiveManagerRents(params);
        this.$toast.open({
          type: "success",
          message: "Успешно архивировано",
        });
      } catch (e) {
        const message = e.response.data;
        this.$toast.open({
          type: "error",
          message: message || "Что-то пошло не так. Попробуйте позже",
        });
      } finally {
        this.pickedItems = [];
      }
      this.pickAvailable = false;
      this.pickedItems = [];
      this.$refs["rents-table"].$refs.table.refresh();
      this.getManagerRents();
    },
  },
};
</script>
