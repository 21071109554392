<template>
  <div class="tasks-desk isesf">
    <div
        v-for="list in lists"
        :key="`list-${list.name}`"
        class="tasks-column"
    >
      <div class="tasks-column__head">
        <div class="tasks-column__title">{{ list.name }}</div>
        <div class="tasks-column__count">{{ list.cards && list.cards.length }}</div>
      </div>
      <div class="tasks-column__list">
        <div
            v-for="card in list.cards"
            :key="`card-${card.id}`"
            :class="{'reverted': card.reverted}"
            class="oda-card tasks-card"
            @click="goToCard(card)"
        >
          <div class="tasks-card__head">
            <div
                :class="getReadingStatusClass(card.reading_status)"
                class="oda-badge"
            >
              {{ getReadingStatus(card.reading_status) }}
            </div>
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-briefcase"></span>
            {{ card.awp.counterparty }}
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-calendar"></span>
            {{ card.awp.state && card.awp.state['ДатаПодписиОтправителя'] }}
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-radio"></span>
            {{ card.awp.bts }}
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-circle"></span>
            {{ card.awp.state && card.awp.state['Номер'] }}
          </div>
          <div class="tasks-card__foot">
<!--            <div class="tasks-card__bts">-->
<!--              <span class="feather icon-radio"></span>-->
<!--              {{ card.bts }}-->
<!--            </div>-->
<!--            <div class="tasks-card__date">{{ getDateFormat(card.created_at) }}</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'IsesfTasks',

  computed: {
    ...mapState({
      cards: state => state.isesf.isesf_tasks,
    }),
    lists() {
      // if (!this.cards) return;
      const names = ['Новые задачи', 'Просмотрено', 'В процессе', 'Отклонено'];
      const lists = [];
      names.forEach((name) => {
        const item = {
          name,
          cards: this.cards.filter((card) => card.status === name && !card.archived),
        };
        lists.push(item);
      });
      return lists;
    }},

  mounted() {
    this.$store.dispatch('isesf/getIsEsfTasks');
  },

  methods: {
    ...mapActions('isesf', [
        'viewIsesfTask'
    ]),
    goToCard(card) {
      if (card.status === 'Новые задачи')
        this.viewIsesfTask({ id: card.id });
      this.$router.push(`/tasks/isesf/${card.id}`);
    },
  },
};
</script>
