<template>
  <section class="oda-section auth">
    <div class="auth-card">
      <div class="auth-card__logo">
        <img src="@/assets/images/tele2-logo.svg" alt="tele2" />
      </div>

      <a href="https://arenda.tele2.kz/" target="_blank" class="redirect-link">
        <div class="redirect-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="redirect-block_icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>

          <div class="redirect-block_title">
            Просим клиентов перейти на новую версию
          </div>

          <div class="redirect-block_hint">Нажмите сюда чтобы перейти</div>
        </div>
      </a>

      <form class="auth-form" @submit.prevent="loginUser">
        <oda-form-group
          v-model="user.identifier"
          placeholder="johndoe@gmail.com"
          label="Логин"
          required
          test-id="user-identifier"
        />
        <oda-form-group
          v-model="user.password"
          placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
          label="Пароль"
          type="password"
          required
          test-id="user-password"
        />

        <div class="auth-form__errors">
          <div
            v-for="error in errors"
            :key="error.id"
            class="auth-form__errors-item"
          >
            {{ getErrorMessage(error.message) }}
            <!-- hz! -->
          </div>
        </div>

        <button class="oda-button primary auth-form__submit mt-3" type="submit">
          Войти
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import OdaFormGroup from "@/components/OdaFormGroup.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Auth",
  components: {
    OdaFormGroup,
  },
  data() {
    return {
      user: {},
      privacyPolicy: false,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.auth_errors,
    }),
  },
  methods: {
    ...mapActions("auth", ["login"]),
    loginUser() {
      this.user.identifier = this.user.identifier.toLowerCase();
      this.login(this.user);
    },
    getErrorMessage(message) {
      // TODO: translate error messages on back
      if (message === "Your account has been blocked by an administrator")
        return "Ваш аккаунт был заблокирован администратором";
      if (message === "Too many attempts, please try again in a minute.")
        return "Слишком много попыток, повторите через несколько минут";
      return message === "Identifier or password invalid."
        ? "Логин или пароль неправильный"
        : message;
    },
  },
};
</script>
