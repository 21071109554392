<template>
  <sidebar-menu
      v-if="currentUser && currentUser.id"
      :menu="menuFiltered"
      :collapsed="collapsed"
      theme="white-theme"
      width="260px"
      widthCollapsed="80px"
      class="oda-desktop-only"
      @toggle-collapse="collapsed = !collapsed"
      @item-click="closeNav"
      v-click-outside="closeNav"
  >
    <div slot="header">
      <div class="oda-navbar__logo">
        <img
            src="@/assets/images/tele2-logo.svg"
            alt="tele2-logo"
        >
      </div>
    </div>

    <template v-slot:footer>
      <div>
        <div
            v-if="userRole === 'client'"
            class="oda-navbar__feedback"
            @click="openFeedbackModal"
        >
          <span class="feather icon-alert-circle"></span>
          <div>Обратная связь</div>
        </div>
        <div
            class="oda-navbar__logout"
            @click="logout"
        >
          <span class="feather icon-log-out"></span>
          <div>Выйти</div>
        </div>
      </div>
    </template>

    <span slot="toggle-icon">
    <i v-if="!collapsed" class="feather icon-arrow-left"></i>
    <i v-else class="feather icon-arrow-right"></i>
  </span>
  </sidebar-menu>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Oda-Navbar",
  data() {
    return {
      collapsed: true,
      menu: [
        {
          href: '/',
          title: 'Мой профиль',
          icon: 'feather icon-user',
          access: ['client', 'manager', 'employe', 'employee_new_treaty', 'employee_isesf'],
        },
        {
          href: '/tasks',
          title: 'Задачи',
          icon: 'feather icon-grid',
          access: ['employe', 'employee_isesf'],
        },
        {
          href: '/docs-update',
          title: 'Обновление доков',
          icon: 'feather icon-layers',
          access: ['employe'],
        },
        {
          href: '/new-contracts',
          title: 'Задачи',
          icon: 'feather icon-grid',
          access: ['employee_new_treaty'],
        },
        {
          href: '/new-contracts-archive',
          title: 'Архив',
          icon: 'feather icon-archive',
          access: ['employee_new_treaty'],
        },
        {
          href: '/registry',
          title: 'Реестры',
          icon: 'feather icon-feather',
          access: ['manager'],
        },
        {
          href: '/new-contracts-manager',
          title: 'Новые договоры',
          icon: 'feather icon-grid',
          access: ['manager'],
        },
        {
          href: '/archive',
          title: 'Архив',
          icon: 'feather icon-archive',
          access: ['manager', 'employe'],
        },
      ],
    }
  },

  computed: {
    ...mapState({
      clientContractType: (state) => state.profile.contract_type,
      client_type: (state) => state.auth.client_type,
    }),
    menuFiltered() {
      const { path } = this.$route;
      if (path === '/terms-of-use') return [];
      const role = this.userRole;
      if (!role) return;
      return this.menu.filter(item => {
        if (item.href === '/rents') {
          return (this.clientContractType || {}).hasRent && this.client_type === 'entity' && item.access.includes(role);
        }
        else if (item.href === '/payment')
          return (this.clientContractType || {}).hasElectricity && item.access.includes(role);
        return item.access.includes(role);
      })
    }
  },

  async mounted() {
    if (!this.clientContractType && this.userRole === 'client') await this.getContractType();
  },

  methods: {
    ...mapActions('profile', [
      'getContractType',
    ]),
    closeNav() {
      this.collapsed = true;
    },
    logout() {
      this.$store.commit('profile/SET_CONTRACT_TYPE', null);
      this.$store.dispatch('auth/logout');
    },
    openFeedbackModal() {
      this.$emit('openModal');
    },
  },
}
</script>
